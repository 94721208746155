<template>
  <div class="container-xl vacancy-list p-0 pt-10" v-if="vacancy">
    <vacancy-full :item="vacancy" no-scroll force-focus />
  </div>
</template>

<script>
import companyMixin from "@state/company";
import vacancyMixin from "@state/vacancy";
import { SearchIcon } from "@vue-hero-icons/solid";
import VacancyFull from "@blocks/vacancy/VacancyFull.vue";
import { ArrowLeftIcon } from "@vue-hero-icons/outline";
import { useVacancies } from "@state/vacancy/vacancies";
import { useApplications } from "@state/user/applications";

export default {
  // VIA vacancyMixin
  //   setup() {
  //     const vacancies = useVacancies();
  //     const applications = useApplications();

  //     return {
  //       vacancies,
  //       applications,
  //     };
  //   },

  mixins: [companyMixin, vacancyMixin],
  components: {
    VacancyFull,
    ArrowLeftIcon,
    SearchIcon,
  },
  props: {
    vacancyId: Number | String,
  },
  data() {
    return {
      config: null,
      currentRoute: window.location.href,
      vacancy: null,
      related: null,
    };
  },
  created() {
    this.vacancies
      .read(this.vacancyId, false)
      .then((result) => {
        if (result.data.not_found) {
          this.$router.push({ name: "vacancy-search" });
        }

        this.vacancy = result.data;
      })
      .catch(() => {
        this.$router.push({ name: "page-error-404" });
      });
  },
  methods: {
    copyVacancyLink() {
      this.$refs.copyLink.focus();
      document.execCommand("copy");
    },
    // enroll(id) {
    //   this.$root.loading = true;
    //   if (this.$auth.check()) {
    //     this.applications
    //       .enroll(id)
    //       .then((response) => {
    //         if (response.data.original.success) {
    //           this.$root.loading = false;

    //           this.$bvModal.msgBoxOk(
    //             "Complemente seu currículo para aumentar as chances de ser encontrado.",
    //             {
    //               title: "Candidatura confirmada!",
    //               size: "md",
    //               buttonSize: "md",
    //               okVariant: "primary",
    //               centered: true,
    //             }
    //           );
    //         } else {
    //           this.$root.loading = false;
    //           this.$bvModal.msgBoxOk(response.data.original.message, {
    //             title: "Falha na candidatura!",
    //             size: "md",
    //             buttonSize: "md",
    //             okVariant: "danger",
    //             centered: true,
    //           });
    //         }
    //       })
    //       .finally(() => {
    //         this.$root.loading = false;
    //       });
    //   } else {
    //     this.$root.loading = false;
    //     this.$router.push({
    //       name: "register1",
    //       params: { vacancy_id: id },
    //     });
    //   }
    // },
  },
};
</script>
