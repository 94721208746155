<template>
  <div id="page-home">
    <div class="left-panel" v-if="!isLoading">
      <Banner
        :progress="
          Math.floor(
            (dashboard.data.curriculum.progress.current /
              dashboard.data.curriculum.progress.max) *
              100
          )
        "
        :num-jobs="dashboard.data.jobs.active"
      />

      <div class="panel-items">
        <div
          class="panel-card-item"
          @click="$router.push({ name: 'profile-edit' })"
        >
          <div class="panel-card-body">
            <div class="panel-card-icon">
              <b-icon icon="person-fill" />
            </div>
            <p>{{ $t("home.resume") }}</p>

            <span
              >{{
                Math.floor(
                  (dashboard.data.curriculum.progress.current /
                    dashboard.data.curriculum.progress.max) *
                    100
                )
              }}% {{ $t("home.completeness") }}</span
            >
          </div>
          <div class="panel-card-footer">
            <b-progress :max="100" variant="primary">
              <b-progress-bar
                :value="dashboard.data.curriculum.progress.total * 100"
              />
            </b-progress>
          </div>
        </div>

        <div
          class="panel-card-item"
          @click="$router.push({ name: 'profile-applications' })"
        >
          <div class="panel-card-body">
            <div class="panel-card-icon">
              <b-icon icon="briefcase-fill" />
              <span
                class="panel-card-indicator"
                v-if="dashboard.data.jobs.pending > 0"
                ><b-icon icon="circle-fill" variant="danger"
              /></span>
            </div>
            <p>
              {{ $tc("home.active-vacancies", dashboard.data.jobs.active) }}
            </p>
            <span>{{
              $tc("home.pending-items", dashboard.data.jobs.pending)
            }}</span>
          </div>
        </div>

        <div
          class="panel-card-item"
          @click="$router.push({ name: 'profile-messages' })"
        >
          <div class="panel-card-body">
            <div class="panel-card-icon">
              <b-icon icon="chat-dots-fill" />
              <span
                class="panel-card-indicator"
                v-if="dashboard.data.messages.unread > 0"
                ><b-icon icon="circle-fill" variant="danger"
              /></span>
            </div>
            <p>{{ $t("home.messages") }}</p>
            <span>{{
              $tc("home.unread-messages", dashboard.data.messages.unread)
            }}</span>
          </div>
        </div>
      </div>

    </div>

    <div class="right-panel"></div>

    <div class="right-panel" v-if="survey && survey.list.length > 0">
      <router-link
        :to="{ name: 'evaluation-disc-form' }"
        v-slot="{ navigate, href, isExactActive }"
      >
        <a :href="href" @click="navigate" :class="{ active: isExactActive }">
          <span>DISC</span>
        </a>
      </router-link>

      <h4>{{ $t("home.appointments") }}</h4>

      <div
        class="appointments"
        v-for="(evaluation) in survey.list"
        :key="evaluation.id"
      >
        <router-link
          :to="{
            name: 'evaluation-survey-execute',
            params: { requestId: evaluation.request_candidate_uuid },
          }"
          v-slot="{ navigate, href, isExactActive }"
        >
          <a :href="href" @click="navigate" :class="{ active: isExactActive }">
            <span>{{ evaluation.id }}</span>
          </a>
        </router-link>
      </div>
    </div>

    <modal-terms-and-conditions />

    <NpsWidget :enrolling="false" />
  </div>
</template>

<script>
import Banner from "./home/Banner.vue";
import { useDashboard } from "@state/user/dashboard";
import { useSurvey } from "@state/evaluation/survey";
import NpsWidget from "@components/help/NpsWidget.vue";
import ModalTermsAndConditions from "@components/help/ModalTermsAndConditions.vue";
import * as _ from "lodash-es";
export default {
  components: {
    Banner,
    NpsWidget,
    ModalTermsAndConditions
  },
  data() {
    return {
      appointments: null,
      terms_conditions: null,
    };
  },
  setup() {
    const survey = useSurvey();
    const dashboard = useDashboard();
    const isLoading = true;

    return {
      isLoading,
      dashboard,
      survey,
    };
  },
  created() {
    
    this.$root.loading = true;

    this.dashboard
      .read()
      .then((response) => {
        this.survey.load().then(() => {
          this.$root.loading = false;
          this.isLoading = false;
        });
      })
      .finally(() => {
        this.$root.loading = false;
        this.isLoading = false;
      });

      if (
        !_.isUndefined(this.$root.$gtm)
        && this.$auth.check()
    ) {
        this.$root.$gtm.trackView(
            this.$route.name,
            this.$route.fullPath,
            {email: this.$auth.user().email}
        )
    }
  },
};
</script>
