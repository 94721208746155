<template>
  <b-modal
    id="modal-terms-conditions"
    size="lg"
    @ok="acceptTerms"
    ok-only
    scrollable
    no-close-on-backdrop
    hide-header-close
    :ok-title="$t('common.accepted')"
  >
    <div v-html="terms_conditions"></div>
  </b-modal>
</template>

<script>
import { useDashboard } from "@state/user/dashboard";

export default {
  data() {
    return {
      terms_conditions: null,
    };
  },
  setup() {
    const dashboard = useDashboard();
    return {
      dashboard,
    };
  },
  created() {
    this.dashboard.termsAndConditionsByUser().then((response) => {
      if (!response.data.success && response.data.terms) {
        this.terms_conditions = response.data.terms;
        this.$bvModal.show("modal-terms-conditions");
      }
    });
  },
  methods: {
    acceptTerms() {
      this.dashboard.acceptTerms()
      .then((response) => {})
      .catch((error) => {});
    },
  },
};
</script>
