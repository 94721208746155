<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <div class="d-flex flex-column gap-6">
        <b-skeleton width="55%" height="10%"></b-skeleton>
        <!-- <b-skeleton-icon
          icon="person"
          :icon-props="{ fontScale: 1 }"
        ></b-skeleton-icon> -->
        <!-- <b-skeleton type="button"></b-skeleton>             -->
        <div
          class="d-flex align-items-center justify-content-start gap-2"
          v-if="!compact"
        >
          <b-skeleton-icon icon="clock"></b-skeleton-icon>
          <b-skeleton width="15%"></b-skeleton>

          <b-skeleton-icon icon="briefcase-fill"></b-skeleton-icon>
          <b-skeleton width="9%"></b-skeleton>

          <b-skeleton-icon icon="geo-alt-fill"></b-skeleton-icon>
          <b-skeleton width="25%"></b-skeleton>
        </div>
        <!-- <vacancy-tags v-if="!compact" :item="item" :size="4" /> -->
        <div :class="{ 'd-flex gap-2': !compact }">
          <b-skeleton
            type="button"
            class="rounded-pill"
            width="15%"
          ></b-skeleton>
          <b-skeleton
            type="button"
            class="rounded-pill"
            width="15%"
          ></b-skeleton>
        </div>
      </div>
    </template>
    <div
      class="d-flex gap-6 px-6 py-5 d-flex"
      :class="{
        'flex-column': !compact,
        'justify-content-between align-items-center': compact,
      }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">
          <h3 v-if="item.occupation" class="text-2xl">
            {{ item.occupation }}
            <fa-icon
              v-if="item.is_special_needs_job || (item.info_special_needs != null && item.info_special_needs != '')"
              icon="fa-solid fa-wheelchair"
              class="text-primary"
            />
          </h3>
          <h5 class="text-sm">
            {{
              item.company_name
                ? item.company_name
                : item.publisher_company
                ? item.publisher_company
                : ""
            }}
          </h5>
        </div>

        <div v-if="!compact">
          <vacancy-share-button :item="item" />
        </div>
      </div>
      <div class="attrs" v-if="!compact">
        <span class="mr-2" v-if="item.published_date">
          <b-icon icon="clock" /> {{ $tc("vacancy.published_at_x_days", getPublicationDays) }}
        </span>

        <span class="mr-2" v-if="item.total_vacancies">
          <b-icon icon="briefcase-fill" />
          {{ item.total_vacancies }} {{ $tc("vacancy.vacancies", item.total_vacancies) }}
        </span>
        <span v-if="getLocalization">
          <b-icon icon="geo-alt-fill" />
          {{ getLocalization }}
        </span>
      </div>

      <vacancy-tags v-if="!compact" :item="item" :size="4" />
      <div class="text-nowrap" :class="{ 'd-flex gap-2': !compact }">
        <!-- <b-button pill variant="outline-primary mr-4">Compartilhar</b-button> -->
        <b-button
          pill
          @click="enroll(item)"
          variant="primary"
          :size="compact ? 'sm' : 'md'"
          :disabled="isEnrolled(item.id)"
        >
          {{
            isEnrolled(item.id)
              ? $t("vacancy.actions.already-applied")
              : $t("vacancy.actions.apply")
          }}
        </b-button>

        <span id="save-vacancy-title">
          <b-button
            pill
            variant="outline-primary mr-4"
            @click="bookmark(item.id)"
            :size="compact ? 'sm' : 'md'"
            :disabled="!$auth.check()"
            v-if="!company.config.is_agreggator"
          >
            {{
              bookmarked
                ? $t("vacancy.actions.unfavorite")
                : $t("vacancy.actions.favorite")
            }}
          </b-button>
        </span>

        <b-tooltip
          target="save-vacancy-title"
          v-if="!bookmarked && !company.config.is_agreggator"
          >{{
            $auth.check()
              ? $t("vacancy.actions.save_vacancy")
              : $t("vacancy.actions.log_to_save")
          }}</b-tooltip
        >
      </div>

      <!-- <VacancyEnrollModal :item="item" ref="enrollModal" /> -->
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import * as _ from "lodash-es";

import VacancyTags from "./VacancyTags.vue";
import { useTheme } from "@state/system/theme";
import VacancyShareButton from "./VacancyShareButton.vue";
import { VBModal } from "bootstrap-vue";
import companyMixin from "@state/company";
import CompanyForm from "@blocks/company/Form.vue";
import { useVacancies } from "@state/vacancy/vacancies";
import { useApplications } from "@state/user/applications";

import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ShareIcon,
  LinkIcon,
} from "@vue-hero-icons/outline";

export default {
  mixins: [companyMixin],
  components: {
    LinkIcon,
    ShareIcon,
    VacancyTags,
    VacancyShareButton,
    CompanyForm,
    CheckCircleIcon,
    useTheme,
    ExclamationCircleIcon,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    tags: {
      type: Boolean,
      required: false,
      default: true,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    // bookmarked: {
    //   type: Boolean,
    //   default: false,
    // },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentRoute: window.location.host,
      application_form: null,
      application_success: false,
      already_applied: false,
      application_error_message: "",
      favorite_success: false,
      modalShow: false,
    };
  },
  computed: {
    bookmarked() {
      return _.some(this.vacancies.favorites, { id: this.item.id });
    },
    getLocalization() {
      var localization = "";

      if (this.item.city) {
        localization +=
          this.item.city +
          (this.item.workplace_complement || this.item.state ? ", " : "");
      }

      if (this.item.workplace_complement) {
        localization +=
          this.item.workplace_complement + (this.item.state ? " - " : "");
      }

      if (this.item.state) {
        localization += this.item.state + (this.item.country ? " - " : "");
      }

      if (this.item.country) {
        localization += this.item.country;
      }

      return localization.length > 0 ? localization : false;
    },
    getPublicationDays() {
      var publication_date = new Date(this.item.published_date).getTime();
      var today = new Date().getTime();

      var difference = today - publication_date;

      // var final_string =
      //   Math.ceil(difference / (1000 * 3600 * 24)) > 1 ? " dias" : " dia";

      return Math.ceil(difference / (1000 * 3600 * 24));
    },
  },
  setup() {
    const applications = useApplications();
    const vacancies = useVacancies();

    const images = import.meta.glob("/resources/assets/images/social/*.png", {
      import: "default",
      eager: true,
    });

    return {
      applications,
      vacancies,
      images,
    };
  },
  mounted() {
    if (this.$auth.check()) {
      this.$auth.fetch();
      this.applications.load();
    }
  },
  methods: {
    copyVacancyLink() {
      this.$copyText(this.currentRoute + "/vaga/" + this.item.id);
    },
    isEnrolled(id) {
      if (!this.$auth.check()) {
        return false;
      }
      if (this.applications.list) {
        return _.size(_.find(this.applications.list, { id: id })) > 0;
      }
    },
    enroll(item) {
      if (this.company.config.is_agreggator) {
        this.$bvModal
          .msgBoxConfirm(
            "Você será redirecionado para o portal " + item.publisher_company,
            {
              title: "Redirecionando",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "Ok",
              cancelTitle: "Cancelar",
              footerClass: "p-2 rounded-pill",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              window.open(item.enroll_url, "_blank");
            }
          })
          .catch((err) => {
            // An error occurred
          });
      } else if (this.$auth.check() && _.isEmpty(this.$auth.user().cpf)) {
        this.$router.push({
          name: "complete-registration",
        });
      } else {
        if (this.$auth.check()) {
          this.$router.push({
            name: "vacancy-enroll",
            params: { vacancy_id: item.id },
          });
        } else {
          this.$router.push({
            name: "register1",
            params: { vacancy_id: item.id },
          });
        }
      }
    },
    bookmark(id) {
      this.vacancies
        .favorite_vacancy(id)
        .then((response) => {
          // this.$nextTick(() => {
          //   this.bookmarked = response.data.favorited;
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
